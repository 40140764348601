import { useFormik, Field } from "formik";
import * as Yup from "yup";
import RedBg from "../assets/redbg.jpg";
import { useState } from "react";
import { toast } from "react-toastify";
import { ImSpinner2 } from "react-icons/im";

const validationSchama = Yup.object({
  firstname: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot be more than 50 characters")
    .required("Name is required"),
  lastname: Yup.string()
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name cannot be more than 50 characters")
    .required("Last name is required"),
  email: Yup.string()
    .matches(/^[\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/, "Invalid email address")
    .required("Email is required"),
  phone: Yup.string()
    .matches(
      /^\+?[1-9]\d{1,14}$/,
      "Phone number is not valid. Please use international format (e.g., +123456789)"
    )
    .required("Phone number is required"),
  message: Yup.string()
    .min(20, "Message is too short, please describe a bit more")
    .max(500, "Message seems to be quite long, make a bit short")
    .required("Please write something down"),
  privacy: Yup.boolean()
    .oneOf([true], "You must accept the privacy policy")
    .required("You must agree to the terms and conditions"),
});

const mailApiUrl = process.env.REACT_APP_API_URL;

const Contact = () => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
      privacy: false,
    },
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: validationSchama,
    onSubmit: (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("firstname", values.firstname);
      formdata.append("lastname", values.lastname);
      formdata.append("email", values.email);
      formdata.append("phone", values.phone);
      formdata.append("message", values.message);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(mailApiUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          setLoading(false);
          console.log(result);
          if (result.status === "success") {
            toast.success(result.msg);
            formik.handleReset();
          } else {
            toast.error(result.msg);
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    },
  });

  return (
    <>
      <section className="p-20 h-[500px] bg-customPrimaryColor">
        <p className="text-customSecondaryBlack text-lg ">
          Home{" "}
          <span className="text-customWhiteColor font-gotham">
            / Contact Us
          </span>
        </p>
        <h1 className="pt-8 text-customWhiteColor font-extrabold text-h1sm md:text-h1md lg:text-h1lg leading-normal font-ralway">
          CONTACT US
        </h1>
      </section>
      <section className="relative h-[1100px] md:h-[700px] lg:h-[700px] bg-[#f6f6f6]">
        <div className="absolute bg-white p-6 md:p-10 w-[90%] sm:w-[80%] md:w-[70%] left-[50%] translate-x-[-50%] rounded-2xl top-[-200px] md:top-[-250px]  shadow">
          <h1 className="text-center font-extrabold text-h1sm md:text-h1md lg:text-h1lg leading-normal text-customPrimaryBlack font-ralway">
            REQUEST A QUOTE
          </h1>
          <p className="text-center leading-normal text-md text-[#c1c1c1] font-gotham">
            Please fill out the form and one of our experts will contact you as
            soon as possible.
          </p>
          <div className="m-8">
            <form
              className="flex flex-col gap-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col md:flex-row gap-10">
                <div className="md:w-[50%]">
                  <label className="text-customPrimaryColor font-gotham">
                    Name
                  </label>
                  <br />
                  <input
                    className={`border ${
                      formik.errors.firstname && formik.touched.firstname
                        ? "border-red-600"
                        : "border-slate-400"
                    } bg-[#fff] w-[100%] px-4 py-3 rounded-md mt-2 outline-none font-gotham`}
                    placeholder="Enter Your Name"
                    name="firstname"
                    value={formik.values.firstname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.firstname && formik.touched.firstname && (
                    <span className="error text-red-600 text-sm">
                      {formik.errors.firstname}
                    </span>
                  )}
                </div>
                <div className="md:w-[50%]">
                  <label className="text-customPrimaryColor font-gotham">
                    Last name
                  </label>
                  <br />
                  <input
                    className={`border ${
                      formik.errors.lastname && formik.touched.lastname
                        ? "border-red-600"
                        : "border-slate-400"
                    } bg-[#fff] font-gotham w-[100%] px-4 py-3 rounded-md mt-2 outline-none`}
                    placeholder="Enter Your Last Name"
                    name="lastname"
                    value={formik.values.lastname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.lastname && formik.touched.lastname && (
                    <span className="error text-red-600 text-sm">
                      {formik.errors.lastname}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-10">
                <div className="md:w-[50%]">
                  <label className="text-customPrimaryColor font-gotham">
                    E-mail
                  </label>
                  <br />
                  <input
                    className={`border ${
                      formik.errors.email && formik.touched.email
                        ? "border-red-600"
                        : "border-slate-400"
                    } font-gotham bg-[#fff] w-[100%] px-4 py-3 rounded-md mt-2 outline-none`}
                    placeholder="Enter Your E-mail"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <span className="error text-red-600 text-sm">
                      {formik.errors.email}
                    </span>
                  )}
                </div>
                <div className="md:w-[50%]">
                  <label className="text-customPrimaryColor font-gotham">
                    Phone
                  </label>
                  <br />
                  <input
                    className={`border ${
                      formik.errors.phone && formik.touched.phone
                        ? "border-red-600"
                        : "border-slate-400"
                    } font-gotham bg-[#fff] w-[100%] px-4 py-3 rounded-md mt-2 outline-none`}
                    placeholder="Enter Your Phone"
                    name="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.phone && formik.touched.phone && (
                    <span className="error text-red-600 text-sm">
                      {formik.errors.phone}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <label className="text-customPrimaryColor font-gotham">
                  Message
                </label>
                <br />
                <textarea
                  className={`border ${
                    formik.errors.message && formik.touched.message
                      ? "border-red-600"
                      : "border-slate-400"
                  } bg-[#fff] w-[100%] px-4 py-3 rounded-md mt-2 font-gotham focus:ring-0 outline-none resize-none`}
                  placeholder="Enter your Message"
                  rows={4}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.message && formik.touched.message && (
                  <span className="error text-red-600 text-sm">
                    {formik.errors.message}
                  </span>
                )}
              </div>
              <div className="mb-0">
                <div>
                  <label className="text-slate-600 text-sm font-gotham">
                    <input
                      type="checkbox"
                      name="privacy"
                      checked={formik.values.privacy}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className="w-4 h-4 me-2"
                    />
                    You read and agree to our{" "}
                    <span className="text-customPrimaryColor">
                      Privacy Policy.
                    </span>
                  </label>
                </div>
                {formik.errors.privacy && formik.touched.privacy && (
                  <span className="error text-red-600 text-sm">
                    {formik.errors.privacy}
                  </span>
                )}
              </div>
              <div>
                <label className="text-slate-600 text-sm font-gotham">
                  <input
                    type="checkbox"
                    name="newsletter"
                    value={1}
                    className="w-4 h-4 me-2"
                  />
                  By checking this box, you agree to receive text messages from
                  J & Harry Express Inc. You can reply “STOP” to opt-out at any
                  time.
                </label>
              </div>
              <div>
                <button
                  type="submit"
                  className={`text-lg text-white px-10 py-3 rounded-md font-gotham ${
                    loading ? "bg-slate-800" : "bg-btnColor"
                  }`}
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex align-center justify-center gap-2">
                      <ImSpinner2 className="text-white animate-spin mt-1" />
                      <span>Please wait....</span>
                    </span>
                  ) : (
                    "Request a Quote"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
